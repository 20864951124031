import React, {useState} from 'react'
import Axios from "axios";
import { saveAs } from 'file-saver'
import {
	peopleLinks,
	icon,
	linkEmail,
	linkVcard,
	linkLinkedin,
	linkPdf,
} from './PeopleLinks.module.scss'

const PeopleLinks = ({ links, ...props }) => {
	if (typeof window === 'undefined') {
		return null
	}

	const { email, vcard, linkedin } = links

	const [pdfDisabled, setPdfDisabled] = useState(false)

	const generatePdf = (params) => {
		setPdfDisabled(true)
		Axios.post('https://us-central1-mindgruve-hosting.cloudfunctions.net/pro-people-pdf', {...params})
			.then(resultData => {
				const blob = new Blob([base64toBlob(resultData?.data)], { type: 'application/pdf' })
				saveAs(blob, `${params.slug}.pdf`)
				setPdfDisabled(false)
			})
			.catch(err => {
				setPdfDisabled(false)
				console.log('====================================')
				console.log(`Something bad happened while fetching the data\n${err}`)
				console.log('====================================')
			})
	}

	return (
		<div className={peopleLinks}>
			{email &&
			<div>
				<a href={email}>
					<span className={[icon, linkEmail].join(' ')}>
						<i className="ss-icon ss-standard ss-mail" />
					</span>
					Email
				</a>
			</div>
			}
			{vcard.link && vcard.name &&
			<div>
				<a href={vcard.link} download={vcard.name}>
					<span className={[icon, linkVcard].join(' ')}>
						<i className="ss-icon ss-standard ss-addcalendar" />
					</span>
					<span className="screen-reader-text">Download </span>
					VCard
				</a>
			</div>
			}
			{linkedin &&
			<div>
				<a href={linkedin}>
					<span className={[icon, linkLinkedin].join(' ')}>
						<i className="fa-brands fa-linkedin-in" />
					</span>
					LinkedIn
				</a>
			</div>
			}
			<div>
				<button onClick={() => generatePdf({data: props.peopleData, slug: `${props.peopleData.slug}`, location: props.location})} disabled={pdfDisabled}>
					<span className={[icon, linkPdf].join(' ')}>
						<i className="ss-icon ss-standard ss-download" />
					</span>
					<span className="screen-reader-text">Download</span> Bio PDF
				</button>
			</div>

			{/* A bit hacky, but keeps flex items flowing and aligned properly*/}
			{!email && <div />}
			{!vcard && <div />}
			{!linkedin && <div />}
		</div>
	)
}

const base64toBlob = (base64Data, contentType) => {
	contentType = contentType || '';
	var sliceSize = 1024;
	var byteCharacters = atob(base64Data);
	var bytesLength = byteCharacters.length;
	var slicesCount = Math.ceil(bytesLength / sliceSize);
	var byteArrays = new Array(slicesCount);

	for (var sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
		var begin = sliceIndex * sliceSize;
		var end = Math.min(begin + sliceSize, bytesLength);

		var bytes = new Array(end - begin);
		for (var offset = begin, i = 0 ; offset < end; ++i, ++offset) {
			bytes[i] = byteCharacters[offset].charCodeAt(0);
		}
		byteArrays[sliceIndex] = new Uint8Array(bytes);
	}
	return new Blob(byteArrays, { type: contentType });
}

export default PeopleLinks
