import React, { useState, useEffect } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import Collapse from 'react-bootstrap/Collapse'
import Link from '../Link'
import PeopleLinks from './PeopleLinks'
import PeopleContent from './PeopleContent'
import Button from '../Button'
import { Button as BsButton } from 'react-bootstrap'
import generateVCard from './generateVCard'
import LanguageSelector from "../LanguageSelector"
import {
	peopleDetail,
	peopleHeading,
	peopleTitle,
	headshotWrapper,
	btnToggleInfo,
	arrowCircle,
	langSelect,
	peopleInformation,
	peopleSectors,
	contactInfo,
	peopleMain,
	peopleInfoWrapper,
} from './PeopleDetail.module.scss'
import clsx from "clsx";
import { GatsbyImage, StaticImage, getImage } from 'gatsby-plugin-image'
import BtnLink from "../Common/StyledButton/BtnLink";

const PeopleDetail = ({ people, location }) => {
	const [open, setOpen] = useState(false)
	const [servicesOpen, setServicesOpen] = useState(false)
	const [activeTab, setActiveTab] = useState('information');

	const handleServicesToggle = (e) => {
		e.preventDefault();
		setServicesOpen(!servicesOpen)
	}
	
	const handleContactClick = (e) => {
		setActiveTab('contact')
	}

	const handleActiveTab = (key) => {
		if (key === 'contact') {
			window.location = `mailto:${peopleData.peopleInformation.email}`;
		} else {
			setActiveTab(key);
		}
	}

	useEffect(() => {
		if (location?.hash === '#tabs-tab-contact') {
			handleContactClick()
		}
	}, [])

	const peopleData = people

	const card = generateVCard(peopleData)
	const links = {
		email: `mailto:${peopleData.peopleInformation.email}`,
		vcard: {
			link: `data:text/vcard;charset=utf-8,${encodeURIComponent(
				card.toString()
			)}`,
			name: peopleData.slug + '.vcf'
		},
		linkedin: peopleData?.peopleInformation?.linkedin,
		// pdf: 'http://google.com',
	}

	let serviceList = []

	if (peopleData?.peopleInformation?.proService) {
		serviceList = peopleData?.peopleInformation?.proService?.map(service => {
			const url = service.uri.replace(
				'/service/',
				`/${service?.serviceTypes?.nodes[0]?.slug}/`
			)
			return {
				text: service.title,
				link: url,
			}
		})
	}

	let specializationsList = []

	if (peopleData?.peopleInformation?.specializations) {
		specializationsList = peopleData?.peopleInformation?.specializations?.map(
			({ specialization }) => {
				return {
					text: specialization,
					link: null,
				}
			}
		)
	}

	const services = [...serviceList, ...specializationsList]

	const headshot =
		peopleData?.featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
	const image = headshot ? getImage(headshot) : null

	const admissionsCount = peopleData?.admissions?.nodes?.length
	const admissions = peopleData?.admissions.nodes.map((admission, index) => (
		admission.name + (admissionsCount > 1 && index < admissionsCount - 1 ? '; <br />' : '')
	)).join(' ')

	const languageCount = peopleData?.languages?.nodes?.length
	const languages = peopleData?.languages.nodes.map((language, index) => (
		language.name + (languageCount > 1 && index < languageCount - 1 ? '; <br />' : '')
	)).join(' ')

	return (
		<section className={peopleDetail}>
			<Container fluid="lg">
				<Row>
					<Col md={4}>
						<div className={`${headshotWrapper} d-none d-md-block`}>
							{image ? (
								<GatsbyImage
									className="img-fluid rounded"
									image={image}
									alt={peopleData.title}
								/>
							) : (
								<StaticImage
									className="img-fluid rounded"
									src="../../images/logo-placeholder.png"
									alt={peopleData?.title}
									width={446}
									height={474}
								/>
							)}
							<PeopleLinks links={links} peopleData={peopleData} location={location} />
						</div>
						{/* headshot + links (DESKTOP) */}
					</Col>
					<Col md={8} lg={{ span: 7, offset: 1 }}>
						<div className={peopleInfoWrapper}>
							<div className={`${langSelect} d-none d-md-block`}>
								<LanguageSelector translations={peopleData.translations} locale={peopleData.locale.locale} />
							</div>
							<header>
								<Row>
									<Col md={8}>
										<div className={peopleHeading}>
											<h1 className={`${peopleTitle} mt-0`}>{peopleData?.title}</h1>

											{peopleData?.jobTitles?.nodes && (
												<h2>
													{peopleData?.jobTitles?.nodes.map(
														(job, i, arr) =>
															`${job.name} ${arr.length > i + 1 ? ' & ' : ''}`
													)}
												</h2>
											)}
										</div>

										<div className={`${langSelect} d-block d-md-none`}>
											<LanguageSelector translations={peopleData.translations} locale={peopleData.locale.locale} />
										</div>

										<div className={[headshotWrapper, 'd-md-none'].join(' ')}>
											{headshot ? (
												<GatsbyImage
													className="img-fluid rounded"
													image={headshot}
													alt={peopleData.title}
												/>
											) : (
												<StaticImage
													className="img-fluid rounded"
													src="../../images/logo-placeholder.png"
													alt={peopleData?.title}
													aspectRatio={1.14}
												/>
											)}
										</div>
										{/* headshot (MOBILE) */}
									</Col>
									<Col md={4}>
										<Button
											buttonText="Contact Me"
											href={peopleData.peopleInformation.email}
											className={`d-none d-lg-block`}
										/>
									</Col>
								</Row>
							</header>

							<hr className="mt-2 mb-4 d-none d-md-block" />

							<BsButton
								className={[
									btnToggleInfo,
									'text-gray-dark text-uppercase d-block d-sm-none mx-auto',
								].join(' ')}
								variant="clear"
								onClick={() => setOpen(!open)}
								aria-controls="people-information"
								aria-expanded={open}
							>
								{open ? (
									<>
										<div>Hide Details</div>
										<div className={arrowCircle}>
											<i className="text-gray-dark ss-icon ss-black-tie-bold ss-up"></i>
										</div>
									</>
								) : (
									<>
										<div>Show Details</div>
										<div className={arrowCircle}>
											<i className="text-gray-dark ss-icon ss-black-tie-bold ss-down"></i>
										</div>
									</>
								)}
							</BsButton>

							<Collapse
								id="people-information"
								in={open}
								className={peopleInformation}
							>
								<div>
									<Row>
										<Col md={6}>
											<div className="pb-4">
												<h3 className="label-small">
													Services & Specializations
												</h3>
												<ul className={peopleSectors}>
													{services?.map((service, index) => (
														<li
															key={index}
															className={clsx(
																!servicesOpen && index > 4 ? 'd-none' : null
															)}
														>
															{service.link ? (
																<Link to={service.link}>{service.text}</Link>
															) : (
																<>{service.text}</>
															)}
														</li>
													))}
												</ul>
												{services?.length > 5 && (
													<BtnLink
														cta={servicesOpen ? 'See Less' : 'See All'}
														useButton={true}
														onClick={handleServicesToggle}
													/>
												)}
											</div>
										</Col>
										{admissions && (
											<Col md={3} className="pb-4">
												<h3 className="label-small">Admissions </h3>
												<p
													className="article-body"
													dangerouslySetInnerHTML={{ __html: admissions }}
												/>
											</Col>
										)}
										{languages && (
											<Col md={3} className="pb-4">
												<h3 className="label-small">Languages </h3>
												<p className="article-body" dangerouslySetInnerHTML={{ __html: languages }} />
											</Col>
										)}
									</Row>

									<hr />

									<div className="pt-3 pb-2">
										<h3 className="label-small">Address</h3>
										{peopleData?.peopleInformation?.proOffice?.map(
											(office, index) => {
												return (
													<p key={index}>
														{office.officeInformation.proOfficeStreet},{' '}
														{office.officeInformation.proOfficeCity},{' '}
														{office.officeInformation.proOfficeState}{' '}
														{office.officeInformation.proOfficeZipcode}{' '}
													</p>
												)
											}
										)}
									</div>

									<div className={contactInfo}>
										<Row>
											{peopleData?.peopleInformation?.phone ? (
												<Col sm={4}>
													<h3 className="label-small">Office Tel. </h3>
													<p>{peopleData?.peopleInformation?.phone}</p>
												</Col>
											) : null}
											{peopleData?.peopleInformation?.mobile ? (
												<Col sm={4}>
													<h3 className="label-small">Mobile Tel. </h3>
													<p>{peopleData?.peopleInformation?.mobile}</p>
												</Col>
											) : null}
											{peopleData?.peopleInformation?.fax ? (
												<Col sm={4}>
													<h3 className="label-small">Fax </h3>
													<p>{peopleData?.peopleInformation?.fax}</p>
												</Col>
											) : null}
										</Row>
									</div>
								</div>
							</Collapse>
						</div>
					</Col>
				</Row>
			</Container>

			<div className={peopleMain}>
				<Container>
					<div className="d-md-none">
						<PeopleLinks links={links} peopleData={peopleData} location={location} />
					</div>
					<PeopleContent people={peopleData} activeTab={activeTab} handleActiveTab={handleActiveTab} />
				</Container>
			</div>
		</section>
	)
}

export default PeopleDetail
