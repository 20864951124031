import { useStaticQuery, graphql } from 'gatsby'

export const useResourcesByAuthor = (authorSlug) => {
  const { allWpPost } = useStaticQuery(
    graphql`
        query AllPostsForAuthors {
            allWpPost(
                sort: { fields: [date], order: DESC }
                filter: {
                    locale: {id: {eq: "en_US"}},
                    resourceTypes: {nodes: {elemMatch: {slug: {ne: "events"}}}}
                }
            ) {
                edges {
                    resource: node {
                        id
                        uri
                        date(formatString: "MMMM DD, YYYY")
                        title
                        categories {
                            nodes {
                                uri
                                name
                            }
                        }
                        resourceTypes {
                            nodes {
                                slug
                                name
                            }
                        }
                        featuredImage {
                            node {
                                altText
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(
                                            layout: CONSTRAINED,
                                            aspectRatio: 1.59,
                                            width: 550
                                        )
                                    }
                                }
                            }
                        }
                        resourceInformation {
                            people {
                                ... on WpPeople {
                                    slug
                                }
                            }
                            service {
                                ... on WpService {
                                    title
                                    uri
                                    serviceTypes {
                                        nodes {
                                            slug
                                            name
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `
  )
  return filterByAuthor(allWpPost?.edges, authorSlug)
}

const filterByAuthor = (resources, authorSlug) => {
  const checkId = obj => obj.slug === authorSlug
  return resources.filter(({ resource }) => {
    return (
      resource?.resourceInformation?.people?.some(checkId)
    )
  })
}
