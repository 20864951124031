import React from "react"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import TabWrapper from "../TabWrapper";
import ProAccordion from "../Accordion";
import OverviewText from "../OverviewText";
import { useResourcesByAuthor } from "../../hooks/use-resources-by-author";
import { useEventsByAuthor } from "../../hooks/use-events-by-author";
import StyledCard from "../Common/StyledCard";
import EventCards from "../../list/resources/Events/EventCards";
import ProcopioForm from "../ProcopioForm";
import { peopleContentWrapper } from "./PeopleContent.module.scss"

const PeopleContent = ({ people, activeTab, handleActiveTab }) => {
	const resources = useResourcesByAuthor(people.slug)
	const events = useEventsByAuthor(people.slug)

	const information = [
		{
			title: 'Representative Matters',
			content: people.peopleContent?.repMatters,
		},
		{
			title: 'Testimonials',
			content: people.peopleContent?.clientTestimonials,
		},
		{
			title: 'Recognitions',
			content: people.peopleContent?.recognitions,
		},
		{
			title: 'Education',
			content: people.peopleContent?.education,
		},
		{
			title: 'Thought Leadership',
			content: people.peopleContent?.thoughtLeadership,
		},
		{
			title: 'Community',
			content: people.peopleContent?.community,
		},
		{
			title: 'Seminars',
			content: people.peopleContent?.seminars,
		},
		{
			title: 'Publications',
			content: people.peopleContent?.publications,
		},
		{
			title: 'News Coverage',
			content: people.peopleContent?.newsCoverage,
		},
	]

  return (
		<>
			<Row className={peopleContentWrapper + ' bg-light'}>
				<Col>
					<TabWrapper>
						<Tabs
							className="border-0 bg-light nav-tabs--alt"
							defaultActiveKey="information"
              activeKey={activeTab}
              onSelect={handleActiveTab}
							id="tabs"
						>
							<Tab
								className="border-0 bg-light"
								eventKey="information"
								title="Information"
							>
								<OverviewText
									title="Overview"
									text={people.peopleContent?.summary}
								/>

								{information
									.filter(obj => obj.content !== null)
									.map(({ title, content }, index) => {
										return content ? (
											<ProAccordion
												title={title}
												content={content}
												index={index + 1}
												key={index}
												first={index === 0}
											/>
										) : null
									})}
							</Tab>
							{!!resources.length && (
								<Tab eventKey="resources" title="Resources">
									<Row>
										{resources.map(({ resource }, index) => (
											<Col xs={6} md={4} key={`people-resource-${index}`}>
												<StyledCard
													iconSize="medium"
													variation="CardD"
													service={resource.resourceInformation.service}
													label={resource.resourceTypes?.nodes[0]?.name}
													labelId={resource.resourceTypes?.nodes[0]?.slug}
													href={resource.uri}
													eventDetails={null}
													image={
														resource.featuredImage?.node?.localFile
															?.childImageSharp?.gatsbyImageData
													}
													imageSize="medium"
													title={resource.title}
												/>
											</Col>
										))}
									</Row>
								</Tab>
							)}
							{!!events.length && (
								<Tab eventKey="events" title="Events">
									<Row>
										<EventCards events={events} title={null} />
									</Row>
								</Tab>
							)}
							<Tab
								eventKey="contact"
								title="Send a message"
							>
							</Tab>
						</Tabs>
					</TabWrapper>
				</Col>
			</Row>
		</>
	)
}

export default PeopleContent
