import vCard from "vcf";
import useSiteMetadata from "../../hooks/use-site-meta";

const generateCard = (profile) => {
  const card = new vCard();
  const {site} = useSiteMetadata()
  const {
    firstName,
    lastName,
    email,
    phone,
    mobile,
    fax,
    proOffice,
  } = profile?.peopleInformation
  card.add("n", `${lastName};${firstName};;;`);
  card.add("fn", `${firstName} ${lastName}`);
  card.add("org", `Procopio`);
  card.add("title", profile?.jobTitles?.nodes.map((job, i, arr) => (
    `${job.name} ${arr.length > i + 1 ? ' & ' : ''}`
  )));
  if (email)  card.add('email', email, {type: ['WORK']})
  if (phone) card.add('tel', phone, {type: ['WORK', 'VOICE']})
  if (mobile) card.add('tel', mobile, {type: ['MOBILE', 'VOICE']})
  if (fax) card.add('tel', fax, {type: ['WORK', 'FAX']})

  if (proOffice) {
    card.add("adr", proOffice?.map((office) => {
        return (
          `;;${office.officeInformation.proOfficeStreet};${office.officeInformation.proOfficeCity};${office.officeInformation.proOfficeState};${office.officeInformation.proOfficeZipcode}`
        )
      }
    ), {type: ['WORK']});
  }
  card.add('url', `${site.siteMetadata.url}${profile.link}`, {type: ['WORK']})
  return card
}

export default generateCard