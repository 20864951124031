// extracted by mini-css-extract-plugin
export var peopleDetail = "PeopleDetail-module--peopleDetail--2MbTp";
export var peopleHeading = "PeopleDetail-module--peopleHeading--RuSpx";
export var headshotWrapper = "PeopleDetail-module--headshotWrapper--VDhzr";
export var peopleInfoWrapper = "PeopleDetail-module--peopleInfoWrapper--12mME";
export var peopleSectors = "PeopleDetail-module--peopleSectors--nl7dK";
export var peopleTitle = "PeopleDetail-module--people-title--13cDi";
export var btnToggleInfo = "PeopleDetail-module--btnToggleInfo--2JmOH";
export var arrowCircle = "PeopleDetail-module--arrowCircle--18rnJ";
export var peopleInformation = "PeopleDetail-module--peopleInformation--1oN_a";
export var contactInfo = "PeopleDetail-module--contactInfo--13sge";
export var peopleMain = "PeopleDetail-module--peopleMain--2Mx1S";
export var langSelect = "PeopleDetail-module--langSelect--3NV8r";