import React from 'react'
import { graphql } from 'gatsby'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Layout from '../components/Layout'
import IconLink from "../components/Common/StyledButton/IconLink";
import PeopleDetailView from '../components/PeopleDetail/PeopleDetailView'
import Seo from 'gatsby-plugin-wpgraphql-seo';

const PeopleDetailPage = ({ location, data }) => {
	const prevState = location?.state
	const peopleData = data.post
	const seo = data.post?.seo
	if (seo?.metaDesc !== '') {
		const metaDescription = peopleData?.peopleContent?.summary?.replace(/<[^>]+>/g, '').trim();
		seo.metaDesc = metaDescription
		seo.opengraphDescription = metaDescription
	}

	return (
		<Layout>
			<Seo post={{seo: seo}} />
			<Container fluid="lg">
				<Row className="pt-4">
					<Col className="mb-4 article-meta">
						<IconLink
							align="right"
							cta="Back to People Directory"
							href={prevState?.path ?? '/people/'}
							size="small"
							iconColor="orange"
							name="left"
							state={prevState}
						/>
					</Col>
				</Row>
			</Container>
			<PeopleDetailView people={peopleData} location={location} />
		</Layout>
	)
}

export default PeopleDetailPage

export const query = graphql`
	query PeopleById(
		# these variables are passed in via createPage.pageContext in gatsby-node.js
		$id: String!
	) {
		# selecting the current post by id
		post: wpPeople(id: { eq: $id }) {
			id
			excerpt
			content
			title
			slug
			link
			date(formatString: "MMMM DD, YYYY")
			seo {
				...SeoFields
			}
			admissions {
				nodes {
					id
					name
					slug
				}
			}
			languages {
				nodes {
					name
					id
					slug
				}
			}
			jobTitles {
				nodes {
					name
					id
					slug
				}
			}
			peopleContent {
				community
				education
				fieldGroupName
				newsCoverage
				publications
				recognitions
				seminars
				summary
				repMatters
				thoughtLeadership
				clientTestimonials
			}
			peopleInformation {
				email
				fax
				fieldGroupName
				firstName
				lastName
				linkedin
				mobile
				phone
				twitter
				proService {
					... on WpService {
						slug
						title
						uri
						serviceTypes {
							nodes {
								slug
								name
							}
						}
					}
				}
				specializations {
					specialization
				}
				proOffice {
					... on WpOffice {
						id
						slug
						officeInformation {
							proOfficeCity
							proOfficeState
							proOfficeStreet
							proOfficeZipcode
						}
						title
					}
				}
			}
			locale {
				id
				locale
			}
			translations {
				id
				locale
				href
			}
			featuredImage {
				node {
					localFile {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED,
								aspectRatio: 0.94,
								width: 600
							)
						}
					}
				}
			}
		}
		site {
			siteMetadata {
				title
			}
		}
	}
`
